import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component } from 'vue-property-decorator';
import { DeviceBasis } from '../../shared/models/dto/devices';
import { NameValue } from '../../shared/models/dto/general';
import { LocationMeasurement } from '../../shared/models/dto/measurement';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { ODataFilterItem, ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';
import { getColoredIconUrl } from '../../shared/helpers/icon-url-generator';

@Component({
    components: {
        dateRangeSelection: require('@/views/_components/date-range-selection/date-range-selection.vue').default,
        errorMessage: require('@/views/_components/error-message/error-message.vue').default,
    }
})

export default class MapComponent extends AuthComponentBase {
    $refs: {
        mapRef: HTMLBaseElement;
    };

    ftdDevices: DeviceBasis[] = [];
    deviceId: number = null;
    sensorId: number = null;
    loading = false;
    loadingFtdDevices = false;

    dataIsLoaded = false;

    map: any = null;
    markers: IMarker[] = [];
    minMaxValue: any = {
        min: null,
        max: null
    };

    minMaxLatLng: any = {
        lat: {
            min: null,
            max: null,
        },
        lng: {
            min: null,
            max: null,
        },
    };

    mapOptions: any = {
        minZoom: 12,
        disableDefaultUI: true,
        clickableIcons: false,
    };
    center: any = { lat: 51.88903, lng: 4.33495 };

    startDate = '';
    endDate = '';
    errors: NameValue[] = [];
    selected = '';

    showDateRangeSelection = false;

    async mounted() {
        (this.$refs.mapRef as any).$mapPromise.then((map) => {
            this.map = map;
        });

        this.getFtdDevices();
    }

    async getFtdDevices() {
        this.loadingFtdDevices = true;

        const query = `?$select=id,name & $filter=vendorDeviceId eq 1 & $expand=sensors($filter=propertyType eq 'ptDouble'; $select=id,displayName)`;

        this.authService.get<DeviceBasis[]>(
            `/api/Devices/${query}`
        ).then((response) => {
            this.ftdDevices = response.content;

            if (this.ftdDevices && this.ftdDevices.length) {
                this.deviceId = this.ftdDevices[0].id;
                this.setSensor();
            }

            this.loadingFtdDevices = false;
            return this.ftdDevices;
        });
    }

    get returnAvailableSensors() {
        if (this.deviceId && this.ftdDevices && this.ftdDevices.length) {
            const deviceObjs = this.ftdDevices.filter(x => x.id = this.deviceId);
            return deviceObjs.length ? deviceObjs[0].sensors : [];
        }

        return [];
    }

    async getItems() {
        this.loading = true;
        this.markers = [];

        const filterItems: ODataFilterItem[] = [];
        filterItems.push({ columnName: 'timeAdded', value: new Date(this.startDate), operator: FilterOperator.gt });
        filterItems.push({ columnName: 'timeAdded', value: new Date(this.endDate), operator: FilterOperator.lt });

        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : []
        };

        const query = this.queryBuilder(queryObj);

        await this.authService.get<IRestResponseDto<LocationMeasurement[]>>(`/api/LocationMeasurements/${query}&deviceId=${this.deviceId}&sensorId=${this.sensorId}`, false).then((response) => {
            const data = response.content as unknown as LocationMeasurement[];
            this.setMarkerArray(data);

            this.dataIsLoaded = true;
        }).finally(() => {
            this.loading = false;
        });
    }

    setMarkerArray(data: LocationMeasurement[]) {
        let sensorName = '';

        if (this.returnAvailableSensors && this.returnAvailableSensors.length) {
            const sensor = this.returnAvailableSensors.find(x => x.id === this.sensorId);
            sensorName = sensor ? sensor.displayName : '';
        }

        if (data && data.length) {
            this.markers = [];

            this.resetMinMaxObj();

            data.forEach(x => {
                // since we are looping, set all the mins and maxes we need later
                this.minMaxValue.min = !this.minMaxValue.min || this.minMaxValue.min > x.value ? x.value : this.minMaxValue.min;
                this.minMaxValue.max = !this.minMaxValue.max || this.minMaxValue.max < x.value ? x.value : this.minMaxValue.max;

                this.minMaxLatLng.lat.min = !this.minMaxLatLng.lat.min || this.minMaxLatLng.lat.min > +x.latitude ? +x.latitude : this.minMaxLatLng.lat.min;
                this.minMaxLatLng.lat.max = !this.minMaxLatLng.lat.max || this.minMaxLatLng.lat.max < +x.latitude ? +x.latitude : this.minMaxLatLng.lat.max;
                this.minMaxLatLng.lng.min = !this.minMaxLatLng.lng.min || this.minMaxLatLng.lng.min > +x.longitude ? +x.longitude : this.minMaxLatLng.lng.min;
                this.minMaxLatLng.lng.max = !this.minMaxLatLng.lng.max || this.minMaxLatLng.lng.max < +x.longitude ? +x.longitude : this.minMaxLatLng.lng.max;

                const newMarker: IMarker = {
                    position: {
                        lat: +x.latitude,
                        lng: +x.longitude,
                    },
                    infoIsOpen: false,
                    errors: [],
                    icon: null,
                    sensorName: sensorName,
                    sensorDate: x.timeAdded,
                    sensorValue: x.value,
                };

                this.markers.push(newMarker);
            });

            this.setMapCenterLocation();
            this.setIcons();
        }
    }

    setMapCenterLocation() {
        if (this.markers) {
            const newLat = (this.minMaxLatLng.lat.max + this.minMaxLatLng.lat.min) / 2;
            const newLng = (this.minMaxLatLng.lng.max + this.minMaxLatLng.lng.min) / 2;

            this.center = { lat: newLat, lng: newLng };

            this.centerMap();
        }
    }

    setSensor() {
        if (this.returnAvailableSensors && this.returnAvailableSensors && this.returnAvailableSensors.length) {
            this.sensorId = this.returnAvailableSensors[0].id;
        }
    }

    setIcons() {
        if (this.markers && this.markers.length) {
            this.markers.forEach(x => {
                x.icon = this.returnIcon(x.sensorValue);
            });
        }
    }

    onDateChange(startDate: string, endDate: string) {
        this.errors = [];
        this.startDate = startDate;
        this.endDate = endDate;

        if (this.startDate && this.endDate) {
            if (new Date(this.startDate) > new Date(this.endDate)) {
                this.errors = [{
                    name: "InvalidDateRange",
                    value: this.t('InvalidDateRange')
                }];
            }
        }
    }

    toggleTooltip(obj: any, index: number, open: boolean) {
        obj.infoIsOpen = open;

        if (open && this.markers) {
            for (let i = 0; i < this.markers.length; i++) {
                if (i !== index) {
                    this.markers[i].infoIsOpen = !open;
                }
            }
        }
    }

    centerMap() {
        this.map.setCenter(this.center);
    }

    returnIcon(val) {
        let color = '';
        const colors = {
            red: '#F44336',
            orange: '#FF9800',
            yellow: '#FFEB3B',
            green: '#4CAF50',
            blue: '#2196F3'
        };

        const mapBetween = Math.round(100 * (val - this.minMaxValue.min) / (this.minMaxValue.max - this.minMaxValue.min));

        if (mapBetween >= 0 && mapBetween <= 20) {
            color = 'red';
        } else if (mapBetween > 20 && mapBetween <= 40) {
            color = 'orange';
        } else if (mapBetween > 40 && mapBetween <= 60) {
            color = 'yellow';
        } else if (mapBetween > 60 && mapBetween <= 80) {
            color = 'green';
        } else {
            color = 'blue';
        }

        return { url: require(`@/assets/images/icons/markers/marker-${color}.png`) };
    }

    resetMinMaxObj() {
        this.minMaxValue = {
            min: null,
            max: null
        };

        this.minMaxLatLng = {
            lat: {
                min: null,
                max: null,
            },
            lng: {
                min: null,
                max: null,
            },
        };
    }
}

export interface IMarker {
    position: IPosition;
    infoIsOpen: boolean;
    errors: any[];
    icon: any;
    sensorName: string;
    sensorDate: Date;
    sensorValue: number;
}

export interface IPosition {
    lat: number;
    lng: number;
}